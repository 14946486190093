import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { CapReceiptItem } from '../PosReceipt/CapReceiptItem';

function ModalCapture(props) {

  const { modals, setModals, receiptItems, getCurrencySymbol, setReceiptItems, clearPreviouslySelectedItems, rest } = props;

  const currentCaptureItem = modals.captureView.currentSelectedItem;
  const tabs = modals.tabsView.tabs;
  const currentTab = tabs[receiptItems.currentSelectedTab.index];
  const uncapturedItemsList = modals.captureView.uncapturedItems.map((item, key) =>
    <CapReceiptItem
      key={key}
      index={key}
      name={item.name}
      quantity={item.quantity}
      price={item.price}
      modals={modals}
      setModals={setModals}
      clearPreviouslySelectedItems={clearPreviouslySelectedItems}
      isUncapturedList={true}
    />
  );

  const itemsToCaptureList = modals.captureView.itemsToCapture.map((item, key) =>
    <CapReceiptItem
      key={key}
      index={key}
      name={item.name}
      quantity={item.quantity}
      price={item.price}
      modals={modals}
      setModals={setModals}
      clearPreviouslySelectedItems={clearPreviouslySelectedItems}
      isUncapturedList={false}
    />
  );

  function hideModal(e) {
    if (e.target.classList.contains("modal-reports") || e.target.classList.contains("modal-background")) {
      clearSelectedItem();
      moveAllItemsToPayableList()
      modals.captureView.isVisible = false;
      setModals({ ...modals })
    }
  }

  function moveItemToPayableList() {
    if (currentCaptureItem.quantity === 1) {
      modals.captureView.uncapturedItems.splice(currentCaptureItem.index, 1);
    } else {
      modals.captureView.uncapturedItems[currentCaptureItem.index].quantity -= 1;
    }
    addItemToArray(modals.captureView.itemsToCapture)
    modals.captureView.balanceToOmit -= currentCaptureItem.price;
    modals.captureView.balanceToCapture += currentCaptureItem.price;
    setModals({ ...modals })
  }

  function moveItemToUncapturedList() {
    if (currentCaptureItem.quantity === 1) {
      modals.captureView.itemsToCapture.splice(currentCaptureItem.index, 1);
    } else {
      modals.captureView.itemsToCapture[currentCaptureItem.index].quantity -= 1;
    }
    addItemToArray(modals.captureView.uncapturedItems)
    modals.captureView.balanceToOmit += currentCaptureItem.price;
    modals.captureView.balanceToCapture -= currentCaptureItem.price;
    setModals({ ...modals })
  }

  function addItemToArray(array) {
    let existingItem = false;
    array.forEach((item, index) => {
      if (item.name === currentCaptureItem.name) {
        array[index].quantity = array[index].quantity + 1;
        existingItem = true;
      }
    });
    if (!existingItem) {
      array.push({
        name: currentCaptureItem.name,
        quantity: 1,
        price: currentCaptureItem.price
      })
    }
    clearSelectedItem();
  }

  function clearSelectedItem() {
    clearPreviouslySelectedItems(modals.captureView.itemsToCapture);
    clearPreviouslySelectedItems(modals.captureView.uncapturedItems);
    modals.captureView.currentSelectedItem = { index: -1 }
  }

  function moveAllItemsToPayableList() {
    modals.captureView.uncapturedItems = [];
    modals.captureView.itemsToCapture = deepCopy(modals.captureView.initialItems);
    modals.captureView.balanceToOmit = 0;
    modals.captureView.balanceToCapture = modals.captureView.balance;
    clearSelectedItem();
    setModals({ ...modals })
  }

  function moveAllItemsToUncapturedList() {
    modals.captureView.uncapturedItems = deepCopy(modals.captureView.initialItems);
    modals.captureView.itemsToCapture = [];
    modals.captureView.balanceToOmit = modals.captureView.balance;
    modals.captureView.balanceToCapture = 0;
    clearSelectedItem();
    setModals({ ...modals })
  }

  function capturePayment() {
    rest.sendCapturePaymentIntentRequest(modals, setModals, modals.captureView.balanceToCapture, currentTab.pid)
    receiptItems.items = deepCopy(modals.captureView.itemsToCapture);
    clearTabItems();
  }

  function clearTabItems() {
    clearPOSItems();
    currentTab.items = deepCopy(modals.captureView.itemsToCapture);
    currentTab.balance = modals.captureView.balanceToCapture;
    modals.captureView.isVisible = false;
    modals.tabsView.isTabSelected = false;
    setModals({ ...modals });
  }

  function clearPOSItems() {
    receiptItems.currentSelectedTab = { index: -1 };
    receiptItems.isVisible = true;
    receiptItems.balance = 0;
    receiptItems.amountHeld = 0;
    receiptItems.amountCaptured = 0;
    receiptItems.items = [];
    receiptItems.receipt = null;
    setReceiptItems({ ...receiptItems })
    clearPreviouslySelectedItems(tabs, -1)
  }

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function isAnUncapturedItemSelected() {
    return currentCaptureItem.index !== -1 && currentCaptureItem.isUncapturedList === true;
  }

  function isAnItemToCaptureSelected() {
    return currentCaptureItem.index !== -1 && currentCaptureItem.isUncapturedList === false;
  }

  function isNoItemSelectedWithSomeUncapturedItems() {
    return currentCaptureItem.index === -1 && modals.captureView.uncapturedItems.length > 0;
  }

  function isNoItemSelectedWithSomeItemsToCapture() {
    return currentCaptureItem.index === -1 && modals.captureView.itemsToCapture.length > 0;
  }

  return (
    <div onClick={(e) => hideModal(e)} className={`modal-background ${modals.captureView.isVisible === true ? "show" : ""}`}>
      <div id="reportsContainer" className={`modal modal-reports modal-reports fade bs-example-modal-sm ${modals.captureView.isVisible === true ? "show" : ""}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg custom-modal-large" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title" id="myModalLabel_reports">Capture Payment</h4>
            </div>
            <div className="modal-body">
              <div className="row text-center">
                <div className="col-md-5">
                  <div className="receipt-before-zigzag" />
                  <div className="receipt" style={{ "height": "240px" }}>
                    {uncapturedItemsList}
                  </div>
                  <div className="receipt-after-zigzag" />
                </div>
                <div className="col-md-2">
                  <button disabled={!isAnUncapturedItemSelected()} onClick={() => moveItemToPayableList()}
                    className={`capture-payment-button chevron-right-single ${isAnUncapturedItemSelected() ? "" : "disabled-button"}`} />
                  <button disabled={!isAnItemToCaptureSelected()} onClick={() => moveItemToUncapturedList()}
                    className={`capture-payment-button chevron-left-single ${isAnItemToCaptureSelected() ? "" : "disabled-button"}`} />
                  <button disabled={!isNoItemSelectedWithSomeUncapturedItems()} onClick={() => moveAllItemsToPayableList()}
                    className={`capture-payment-button chevron-right-double ${isNoItemSelectedWithSomeUncapturedItems() ? "" : "disabled-button"}`} />
                  <button disabled={!isNoItemSelectedWithSomeItemsToCapture()} onClick={() => moveAllItemsToUncapturedList()}
                    className={`capture-payment-button chevron-left-double ${isNoItemSelectedWithSomeItemsToCapture() > 0 ? "" : "disabled-button"}`} />
                </div>
                <div className="col-md-5">
                  <div className="receipt-before-zigzag" />
                  <div className="receipt" style={{ "height": "240px" }}>
                    {itemsToCaptureList}
                  </div>
                  <div className="receipt-after-zigzag" />
                </div>
              </div>
              <div className="row" style={{ "margin": "0" }}>
                <div className="payments">
                  <span className="payments__text">Balance:</span>
                  <span className="payments__amount">
                    <CurrencyFormat value={parseFloat(modals.captureView.balance / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
                  </span>
                </div>
                <div className="payments">
                  <span className="payments__text">Balance to Omit:</span>
                  <span className="payments__amount">
                    <CurrencyFormat value={parseFloat(modals.captureView.balanceToOmit / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
                  </span>
                </div>
                <div className="payments">
                  <span className="payments__text">Balance to Capture:</span>
                  <span className="payments__amount">
                    <CurrencyFormat value={parseFloat(modals.captureView.balanceToCapture / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
                  </span>
                </div>
              </div>
              <div className="row" style={{ "margin": "0" }}>
                <button className={`buttons__button button__card-payment ${modals.captureView.balanceToCapture === 0 ? "disabled-button" : ""}`}
                  disabled={modals.captureView.balanceToCapture === 0}
                  onClick={() => capturePayment()}
                  type="button">
                  {modals.captureView.balanceToCapture === modals.captureView.balance && <>Full Capture</> }
                  {modals.captureView.balanceToCapture < modals.captureView.balance && <>Partial Capture</> }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ModalCapture }