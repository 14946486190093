export const CANCEL_PAYMENT_PATH = "CancelPayment";
export const CANCEL_REFUND_PATH = "CancelRefund";
export const CAPTURE_PAYMENT_PATH = "CapturePayment";
export const PAYMENT_INTENT_PATH = "/payment-intents";
export const TERMINAL_SESSION_PATH = "/terminal-sessions";
export const RETRIEVE_ALL_TERMINALS_PATH = "/terminals";
export const CREATE_REFUND_PATH = "CreateRefund";
export const RECORD_PAYMENT_PATH = "RecordPayment";
export const RECORD_REFUND_PATH = "RecordRefund";
export const REVERSE_PAYMENT_PATH = "ReversePayment";
export const SIGNATURE_VERIFICATION_PATH = "SignatureVerification";
export const TERMINAL_DETAILS_PATH = "TerminalDetails";
export const TERMINAL_NOTIFICATION_PATH = "terminalNotification";
export const UPDATE_PAYMENT_PATH = "UpdatePayment";
export const DISCONNECT_PATH = "Disconnect";

export const CAPTURE_MODE_AUTO = "Auto";
export const CAPTURE_MODE_MANUAL = "Manual";

export const PAYMENT_STATUS_AUTHORIZED = "Authorized";
export const PAYMENT_STATUS_CAPTURED = "Captured";
export const PAYMENT_STATUS_CANCELED = "Canceled";
export const PAYMENT_STATUS_DECLINED = "Declined";
export const PAYMENT_STATUS_INITIATE_REQUESTED = "InitiateRequested";
export const PAYMENT_STATUS_REFUNDED = "Refunded";
export const PAYMENT_STATUS_REJECTED = "Rejected";
export const PAYMENT_STATUS_EXPIRED = "Expired";
export const PAYMENT_STATUS_SIGNATURE_VERIFICATION_REJECTED = "SignatureVerificationRejected";
export const PAYMENT_STATUS_NOT_REFUNDED = "NOT_REFUNDED";

export const AUTO_EXPIRE_ACTION_CAPTURE = "Capture"
export const AUTO_EXPIRE_ACTION_RELEASE = "Release"

export const TERMINAL_SESSION_SALE_TYPE = "Sale";
export const TERMINAL_SESSION_MATCHED_REFUND_TYPE = "MatchedRefund";

export const TERMINAL_STATUS_AVAILABLE = "AVAILABLE";
export const TERMINAL_STATUS_BUSY = "BUSY";
export const TERMINAL_CURRENCY_EUR = "EUR";
export const TERMINAL_CURRENCY_GBP = "GBP";

export const PAYMENT_METHOD_CARD_PRESENT = "PAYMENT_METHOD_CARD_PRESENT";
export const PAYMENT_METHOD_REMOTE = "PAYMENT_METHOD_REMOTE";

export const SIZE_BODY = "SizeBody";
export const SIZE_HEADER_1 = "SizeHeader1";
export const SIZE_HEADER_2 = "SizeHeader2";

export function createCapturePaymentIntentRequest(baseAmount) {
  return {
    amount: baseAmount
  };
}

export function createUpdatePaymentIntentAmountRequest(baseAmount, currency) {
  return {
    amount: {
      value: baseAmount,
      currencyCode: currency
    }
  }
}

export function createAutoPaymentIntentRequest(baseAmount, currency) {
  return {
    amount: {
      value: baseAmount,
      currencyCode: currency
    },
    reference: "Omni EPOS",
    description: "Omni EPOS",
    captureMode: CAPTURE_MODE_AUTO,
    refund: false
  };
}

export function createManualPaymentIntentRequest(baseAmount, currency) {
  return {
    autoExpireIn: "00.00:05:00",
    autoExpireAction: AUTO_EXPIRE_ACTION_RELEASE, 
    amount: {
      value: baseAmount,
      currencyCode: currency
    },
    reference: "Omni EPOS",
    description: "Omni EPOS",
    captureMode: CAPTURE_MODE_MANUAL,
    refund: false
  };
}

export function createSaleTerminalSessionRequest(terminalId, paymentIntentId) {
  return {
    terminalId: terminalId,
    details: {
      sessionType: TERMINAL_SESSION_SALE_TYPE,
      sale: {
        paymentIntentId: paymentIntentId
      }
    }
  }
}

export function createMatchedRefundTerminalSessionRequest(terminalId, paymentIntentId, refundAmount, currency) {
  return {
    terminalId: terminalId,
    details: {
      sessionType: TERMINAL_SESSION_MATCHED_REFUND_TYPE,
      matchedRefund: {
        amount: {
          value: refundAmount,
          currencyCode: currency
        },
        paymentIntentId: paymentIntentId
      }
    }
  }
}

export function handleAutoSaleTerminalSessionResponse(response, modals, setModals, balance, paymentIntentId, items) {
  if (response.id) {
    modals.transactionsView.transactions.push({
      id: response.id,
      txId: modals.transactionsView.currentTxId,
      pid: paymentIntentId,
      balance: balance,
      status: PAYMENT_STATUS_INITIATE_REQUESTED,
      items: items
    });
    modals.transactionsView.currentTxId++;
    setModals({ ...modals })
  }
}

export function handleManualSaleTerminalSessionResponse(response, modals, setModals, balance, paymentIntentId, items) {
  if (response.id) {
    modals.tabsView.tabs.push({
      id: response.id,
      tabId: modals.tabsView.currentTabId,
      pid: paymentIntentId,
      balance: balance,
      amountHeld: 0,
      amountCaptured: 0,
      status: PAYMENT_STATUS_INITIATE_REQUESTED,
      items: items
    });
    modals.tabsView.currentTabId++;
    setModals({ ...modals })
  }
}

export function handleGetTerminalSessionResponse(response, modals, setModals, txOrTab) {
  let reachedTerminalStatus = true;
  if (response.status) {
    if (![
      PAYMENT_STATUS_AUTHORIZED,
      PAYMENT_STATUS_CAPTURED,
      PAYMENT_STATUS_CANCELED,
      PAYMENT_STATUS_DECLINED,
      PAYMENT_STATUS_EXPIRED,
      PAYMENT_STATUS_SIGNATURE_VERIFICATION_REJECTED,
      PAYMENT_STATUS_REJECTED
    ].includes(response.status)) {
      reachedTerminalStatus = false;
      txOrTab.status = response.notificationEvents.length > 0 ? response.notificationEvents[response.notificationEvents.length -1].notificationType : response.status;
    } else {
      handleReceiptData(response, txOrTab)
      txOrTab.status = response.status === PAYMENT_STATUS_CAPTURED && response.details.sessionType === TERMINAL_SESSION_MATCHED_REFUND_TYPE ? PAYMENT_STATUS_REFUNDED : response.status;
      if (PAYMENT_STATUS_AUTHORIZED === response.status) {
       if (txOrTab.tabId >= 0) {
        txOrTab.amountHeld = txOrTab.balance;
       }
      }
    }
    setModals({ ...modals });
  }
  return reachedTerminalStatus;
}

function handleReceiptData(response, txOrTab) {
  if (response.customerReceipt?.lines) {
    txOrTab.receiptLines = { 
      CUSTOMER: response.customerReceipt.lines, 
      MERCHANT: response.merchantReceipt.lines
    };
  }
}