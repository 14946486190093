import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { PosReceiptItem } from './PosReceiptItem';
import { SIZE_BODY, SIZE_HEADER_1, SIZE_HEADER_2 } from '../../rest/omniApi';

function PosReceipt(props) {

  const { modals, receiptItems, setReceiptItems, getCurrencySymbol, clearPreviouslySelectedItems } = props;

  const receiptItemsList = receiptItems.items.map((item, key) =>
    <PosReceiptItem
      key={key}
      index={key}
      name={item.name}
      quantity={item.quantity}
      price={item.price}
      modals={modals}
      receiptItems={receiptItems}
      setReceiptItems={setReceiptItems}
      getCurrencySymbol={getCurrencySymbol}
      clearPreviouslySelectedItems={clearPreviouslySelectedItems}
    />
  );

  const formattedCustomerReceipt = receiptItems.receiptLines?.CUSTOMER?.map((receiptLine, key) => formatLines(receiptLine, key));
  const seperator = [{text: ""}, {text: ""}, {text: {value: "===================", size: SIZE_HEADER_1}}, {text: ""}].map((receiptLine, key) => formatLines(receiptLine, key));
  const formattedMerchantReceipt = receiptItems.receiptLines?.MERCHANT?.map((receiptLine, key) => formatLines(receiptLine, key));

  function formatLines(receiptLine, key) {
    return (
    <div key={key} className="receipt__selected-product__container">
      {receiptLine.text !== "" &&
        <div className={
          "receipt__selected-product-name pull-left"
          + `${receiptLine.text?.emphasisBold === true ? " format-bold" : ""}`
          + `${receiptLine.text?.size === SIZE_BODY ? " format-body" : ""}`
          + `${receiptLine.text?.size === SIZE_HEADER_1 ? " format-header-1" : ""}`
          + `${receiptLine.text?.size === SIZE_HEADER_2 ? " format-header-2" : ""}`
        }>
          {receiptLine?.text?.value}
        </div>
      }
    </div>);
  }

  return (
    <div className="col-xs-12 col-sm-5 col-md-5">
      <div className="row">
        <div className="receipt-before-zigzag">

        </div>
        {receiptItems.isVisible === true &&
          < div id="receipt" className="receipt">
            {receiptItemsList}
          </div>
        }
        {receiptItems.isVisible === false &&
          <div id="receipt" className="receipt">
            {formattedCustomerReceipt}
            {receiptItems.receiptLines?.CUSTOMER && seperator}
            {formattedMerchantReceipt}
          </div>
        }
        <div className="receipt-after-zigzag">

        </div>
        <div className="payments">
          <span className="payments__text">Balance:</span>
          <span className="payments__amount">
            <CurrencyFormat value={parseFloat(receiptItems.balance / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
          </span>
        </div>
        {receiptItems.currentSelectedTab.index !== -1 &&
          <div className="payments">
            <span className="payments__text">Amount Held:</span>
            <span className="payments__amount">
              <CurrencyFormat value={parseFloat(receiptItems.amountHeld / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
            </span>
          </div>
        }
        {receiptItems.currentSelectedTab.index !== -1 &&
          <div className="payments">
            <span className="payments__text">Amount Captured:</span>
            <span className="payments__amount">
              <CurrencyFormat value={parseFloat(receiptItems.amountCaptured / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
            </span>
          </div>
        }
      </div>
    </div >
  );
}

export { PosReceipt };