// reducers.js

import { REST_REQUEST_CREATED, REST_REQUEST_FINISHED } from "./actions";
import { initialState } from "./state";

export default function reducer(state, action) {
  const currState = state || initialState;

  switch (action.type) {
    case REST_REQUEST_CREATED:
      return {
        ...currState,
        networkLog: [
          ...currState.networkLog,
          {
            id: action.id,
            method: action.method,
            headers: action.headers,
            path: action.path,
            data: action.data,
          },
        ],
      };
    case REST_REQUEST_FINISHED:
      const networkLog = currState.networkLog;
      const logIdx = networkLog.findIndex((l) => l.id === action.id);
      if (logIdx !== -1) {
        networkLog[logIdx].response = action.response;
        return {
          ...currState,
          networkLog,
        };
      }

      return currState;
    default:
      return currState;
  }
}
