import React from 'react';
import { PAYMENT_STATUS_AUTHORIZED, PAYMENT_STATUS_CANCELED, PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_EXPIRED, PAYMENT_STATUS_REFUNDED, PAYMENT_STATUS_REJECTED, PAYMENT_STATUS_SIGNATURE_VERIFICATION_REJECTED } from '../../rest/omniApi';

function Title(props) {

  const { modals, receiptItems } = props;

  const isTabSelected = modals.tabsView.isTabSelected;
  const isTransactionSelected = modals.transactionsView.isTransactionSelected;
  const currentSelectedTabIndex = receiptItems.currentSelectedTab.index;
  const currentSelectedTransactionIndex = receiptItems.currentSelectedTransaction.index;
  const tabs = modals.tabsView.tabs;
  const transactions = modals.transactionsView.transactions;

  function CurrentStatusIsAStableStatus(status) {
    return [
      PAYMENT_STATUS_AUTHORIZED,
      PAYMENT_STATUS_CAPTURED,
      PAYMENT_STATUS_CANCELED,
      PAYMENT_STATUS_EXPIRED,
      PAYMENT_STATUS_REFUNDED,
      PAYMENT_STATUS_REJECTED,
      PAYMENT_STATUS_SIGNATURE_VERIFICATION_REJECTED
    ].includes(status)
  }

  return (
    <div className="row status-row">
      <div id="status" className="status-text col-xs-12">
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_AUTHORIZED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId}</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_CAPTURED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Captured)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_CANCELED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Canceled)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_EXPIRED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Expired)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_REFUNDED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Rejunded)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_REJECTED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Rejected)</>
        }
        {isTabSelected === true && tabs[currentSelectedTabIndex].status === PAYMENT_STATUS_SIGNATURE_VERIFICATION_REJECTED &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Signature Verification Rejected)</>
        }
        {isTabSelected === true && !CurrentStatusIsAStableStatus(tabs[currentSelectedTabIndex].status) &&
          <>Modify Tab: {"#" + tabs[currentSelectedTabIndex].tabId} (Tab Not Authorized)</>
        }
        {isTabSelected === false && isTransactionSelected === false &&
          <>Select Items</>
        }
        {isTransactionSelected === true && !CurrentStatusIsAStableStatus(transactions[currentSelectedTransactionIndex].status) &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Awaiting Authorization)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_CAPTURED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Captured)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_CANCELED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Canceled)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_EXPIRED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Expired)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_REFUNDED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Refunded)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_REJECTED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Rejected)</>
        }
        {isTransactionSelected === true && transactions[currentSelectedTransactionIndex].status === PAYMENT_STATUS_SIGNATURE_VERIFICATION_REJECTED &&
          <>Transaction {"#" + transactions[currentSelectedTransactionIndex].txId} (Transaction Signature Verification Rejected)</>
        }
      </div>
    </div>
  );
}

export { Title }