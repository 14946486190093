import React, { useState } from "react";
import store from "../../store/store";
import "./network-log.css";

function Payload({ id, data, className, titleElement }) {
  const [show, setShow] = useState({});

  return (
    <div
      onClick={() => {
        setShow({
          ...show,
          [id]: !show[id],
        });
      }}
      className={`${className} ${data ? "clickable" : ""}`}
    >
      {titleElement}
      {data && <i className={show[id] ? "arrow up" : "arrow down"}></i>}
      {show[id] && data && (
        <pre className="log-item__data">{JSON.stringify(data, null, 2)}</pre>
      )}
    </div>
  );
}

function NetworkLog() {
  const networkLog = store.getState().networkLog;

  return (
    <div className="log-container">
      {networkLog.reverse().map((log) => (
        <div key={log.id} className="log-item">
          <hr className="dotted-line" />
          <div className="log-item__request">
            <Payload
              id={log.id}
              data={log.data}
              className="log-item__request-data"
              titleElement={
                <>
                  <strong>Request: </strong>
                  {log.method.toUpperCase()} {log.path}
                </>
              }
            />
            <div className="log-item__headers">
              <strong>Headers: </strong>
              <pre className="log-item__data">
                {JSON.stringify(log.headers, null, 2)}
              </pre>
            </div>
          </div>

          <Payload
            id={log.id}
            data={log.response ? log.response.data : null}
            className="log-item__response"
            titleElement={
              <>
                <strong>Response: </strong>
                {log.response ? log.response.status : ""}
              </>
            }
          />
        </div>
      ))}
    </div>
  );
}

export { NetworkLog };
