import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { PAYMENT_STATUS_AUTHORIZED, PAYMENT_STATUS_CANCELED, PAYMENT_STATUS_CAPTURED, PAYMENT_STATUS_DECLINED, PAYMENT_STATUS_EXPIRED, PAYMENT_STATUS_REFUNDED, PAYMENT_STATUS_REJECTED } from '../../rest/omniApi';

function ModalTransactionsOrTabs(props) {
  const { displayMode, modals, setModals, getCurrencySymbol, receiptItems, setReceiptItems, clearPreviouslySelectedItems, clearReceiptItems } = props;

  let txs = [];
  let tbs = [];
  const transactions = modals.transactionsView.transactions;
  const tabs = modals.tabsView.tabs;

  for (let x = 0; x < 10; x++) {
    if (transactions[x]) {
      txs[x] = transactions[x];
    } else {
      txs[x] = { pid: "-", txId: "-", status: "-" };
    }
    if (tabs[x]) {
      tbs[x] = tabs[x];
    } else {
      tbs[x] = { pid: "-", tabId: "-", amountHeld: "-", status: "-" };
    }
  }

  function updateCurrentSelectedTab(e) {
    const selectedTab = parseInt(e.currentTarget.getAttribute('data-id'));
    if (selectedTab < tabs.length) {
      tabs[selectedTab].selected = !tabs[selectedTab].selected;
      clearPreviouslySelectedItems(tabs, selectedTab);
      if (tabs[selectedTab].selected === true) {
        receiptItems.items = tabs[selectedTab].items;
        receiptItems.balance = tabs[selectedTab].balance;
        receiptItems.amountHeld = tabs[selectedTab].amountHeld;
        receiptItems.amountCaptured = tabs[selectedTab].amountCaptured;
        receiptItems.currentSelectedTab = { index: selectedTab };
        receiptItems.receiptLines = tabs[selectedTab].receiptLines;
        modals.transactionsView.isTransactionSelected = false;
        modals.tabsView.isTabSelected = true;
      } else {
        tabs[selectedTab].balance = receiptItems.balance;
        clearPreviouslySelectedItems(tabs[selectedTab].items, -1);
        clearReceiptItems();
      }
      setReceiptItems({ ...receiptItems });
      setModals({ ...modals });
    }
  }

  function updateCurrentSelectedTx(e) {
    const selectedTx = parseInt(e.currentTarget.getAttribute('data-id'));
    if (selectedTx < transactions.length) {
      transactions[selectedTx].selected = !transactions[selectedTx].selected;
      clearPreviouslySelectedItems(transactions, selectedTx);
      if (transactions[selectedTx].selected === true) {
        receiptItems.items = transactions[selectedTx].items;
        receiptItems.balance = transactions[selectedTx].balance;
        receiptItems.amountHeld = 0;
        receiptItems.amountCaptured = 0;
        receiptItems.currentSelectedTransaction = { index: selectedTx };
        receiptItems.receiptLines = transactions[selectedTx].receiptLines;
        modals.transactionsView.isTransactionSelected = true;
        modals.tabsView.isTabSelected = false;
      } else {
        clearReceiptItems();
      }
      setReceiptItems({ ...receiptItems });
      setModals({ ...modals });
    }
  }

  function StatusIsAStationaryState(status) {
    return [
      PAYMENT_STATUS_AUTHORIZED, 
      PAYMENT_STATUS_CAPTURED, 
      PAYMENT_STATUS_CANCELED,
      PAYMENT_STATUS_DECLINED,
      PAYMENT_STATUS_EXPIRED, 
      PAYMENT_STATUS_REFUNDED,
      PAYMENT_STATUS_REJECTED
    ].includes(status);
  }

  const transactionList = txs.map((tx, index) => {
    const currentTxIsSelected = transactions[index]?.selected;
    return (
      <tr key={index} data-id={index} onClick={(e) => updateCurrentSelectedTx(e)} className={(index % 2 === 0 ? "blue-row" : "") + (index === 0 ? " transactions__table__font" : "") + ` transactionRow${tx.pid === "-" ? "" : " with-data"}${currentTxIsSelected ? " selected" : ""}`}>
        {tx.pid !== "-" &&
          <>
            <td className={!StatusIsAStationaryState(tx.status) ? "blink-text" : "blink-text-once"}>{tx.pid}</td>
            <td className={!StatusIsAStationaryState(tx.status) ? "blink-text" : "blink-text-once"}>{tx.txId}</td>
            <td className={!StatusIsAStationaryState(tx.status) ? "blink-text" : "blink-text-once"}>{tx.status}</td>
          </>
        }
        {tx.pid === "-" &&
          <>
            <td>{tx.pid}</td>
            <td>{tx.txId}</td>
            <td>{tx.status}</td>
          </>
        }
      </tr>
    );
  });

  const tabsList = tbs.map((tb, index) => {
    const currentTabIsSelected = tabs[index]?.selected;
    return (
      <tr key={index} data-id={index} onClick={(e) => updateCurrentSelectedTab(e)} className={(index % 2 === 0 ? "blue-row" : "") + (index === 0 ? " transactions__table__font" : "") + ` tabRow${tb.pid === "-" ? "" : " with-data"}${currentTabIsSelected ? " selected" : ""}`}>
        {tb.pid !== "-" &&
          <>
            <td className={!StatusIsAStationaryState(tb.status) ? "blink-text" : "blink-text-once"}>{tb.pid}</td>
            <td className={!StatusIsAStationaryState(tb.status) ? "blink-text" : "blink-text-once"}>{tb.tabId}</td>
            <td className={!StatusIsAStationaryState(tb.status) ? "blink-text" : "blink-text-once"}>
              <CurrencyFormat value={parseFloat(tb.amountHeld / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
            </td>
            <td className={!StatusIsAStationaryState(tb.status) ? "blink-text" : "blink-text-once"}>{tb.status}</td>
          </>
        }
        {tb.pid === "-" &&
          <>
            <td>{tb.pid}</td>
            <td>{tb.tabId}</td>
            <td>{tb.amountHeld}</td>
            <td>{tb.status}</td>
          </>
        }
      </tr >
    );
  });

  function toggleTransactionOrTabsView() {
    const selectedTab = tabs[receiptItems.currentSelectedTab?.index];
    const selectedTx = transactions[receiptItems.currentSelectedTransaction?.index];
    if (selectedTab) {
      selectedTab.balance = receiptItems.balance;
      selectedTab.items = receiptItems.items;
      clearPreviouslySelectedItems(tabs, -1);
      clearPreviouslySelectedItems(selectedTab.items, -1);
      clearReceiptItems()
    }
    if (selectedTx) {
      clearPreviouslySelectedItems(transactions, -1);
      clearReceiptItems()
    }
    modals.transactionsView.isVisible = !modals.transactionsView.isVisible;
    setModals({ ...modals });
  }

  return (
    <>
      <div className={`transactions ${displayMode === 0 ? "visible-md visible-lg" : "visible-xs visible-sm"}`}>
        <div className="transactions__transactions-header text-center">
          {modals.transactionsView.isVisible && <>Transactions</>}
          {!modals.transactionsView.isVisible && <>Tabs</>}
        </div>
        <div className="transactions__transactions-table-container">
          <table className="transactions__transactions-table table text-center">
            <thead>
              {modals.transactionsView.isVisible &&
                <tr>
                  <th>Payment ID</th>
                  <th>Txn ID</th>
                  <th>Status</th>
                </tr>
              }
              {!modals.transactionsView.isVisible &&
                <tr>
                  <th>Payment ID</th>
                  <th>Tab</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              }
            </thead>
            <tbody>
              {modals.transactionsView.isVisible && transactionList}
              {!modals.transactionsView.isVisible && tabsList}
            </tbody>
          </table>
        </div>
      </div>
      <button className={`buttons__button button__card-payment ${displayMode === 0 ? "visible-md visible-lg" : "visible-xs visible-sm"}`}
        onClick={() => toggleTransactionOrTabsView()}
        id="cardPayment"
        type="button">
        {modals.transactionsView.isVisible && <>Show Tabs</>}
        {!modals.transactionsView.isVisible && <>Show Transactions</>}
      </button>
    </>
  );
}

export { ModalTransactionsOrTabs }