import React, { useContext, useEffect, useRef, useState } from 'react';
import './App.css';
import './pos.css';

import retailLogo from '../images/retail-logo.png'
import { Provider, useSelector } from 'react-redux'
import store from '../store/store';
import { PosItemSelection } from './PosItemSelection/PosItemSelectionView';
import { Footer } from './Footer/Footer';
import { useQueryParam, StringParam, QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { BrowserRouter } from 'react-router-dom';
import { initialState } from '../store/state';
import { PosReceipt } from './PosReceipt/PosReceiptView';
import { ModalTransactionsOrTabs } from './Modals/TransactionsOrTabsView';
import { PosButtons } from './PosButtons/PosButtons';
import { ModalPDQSelection } from './Modals/PDQSelectionView';
import { ConnectionError } from './ConnectionErrors/ConnectionErrorView';
import { ModalTabsSelection } from './Modals/TabsSelectionView';
import { Title } from './Title/Title';
import { ModalCapture } from './Modals/CaptureView';
import { NetworkLog } from "./NetworkLog/NetworkLog";
import RestProvider, {RestContext} from '../rest/restClient';
import { TERMINAL_CURRENCY_EUR, TERMINAL_CURRENCY_GBP } from '../rest/omniApi';

function HomeComponent() {
  const [apiKey, setApiKey] = useQueryParam('apiKey', StringParam);
  const [withLog] = useQueryParam('withLog', StringParam);
  const [currency, setCurrency] = useQueryParam('currency', StringParam);
  const [connectionStatus, setConnectionStatus] = useState(store.getState().connectionStatus);
  const [modals, setModals] = useState(store.getState().modals);
  const [receiptItems, setReceiptItems] = useState(store.getState().receiptItems);
  const posItems = useSelector(state => state.posItems);

  const rest = useContext(RestContext);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      rest.checkAccountDetails(apiKey, setConnectionStatus);

      const url = new URL(window.location.href);

      var refresh = false;

      if (!apiKey) {
        setApiKey(initialState.apiKey)
        url.searchParams.delete('apiKey');
        url.searchParams.append('apiKey', initialState.apiKey);
        refresh = true;
      } 

      if (![TERMINAL_CURRENCY_GBP, TERMINAL_CURRENCY_EUR].includes(currency)) {
        setCurrency(initialState.currency)
        url.searchParams.delete('currency');
        url.searchParams.append('currency', initialState.currency);
        refresh = true;
      }

      if (refresh === true) {
        window.location.assign(url.search);
      }
    }
  }, [rest, modals, apiKey, currency, setModals, setApiKey, setCurrency, connectionStatus])

  function getCurrencySymbol() {
    return currency === TERMINAL_CURRENCY_EUR ? '€' : '£';
  }

  function clearPreviouslySelectedItems(array, index) {
    array.forEach((item, i) => {
      if (i !== index) {
        item.selected = false;
      }
    })
  }

  function clearReceiptItems() {
    receiptItems.isVisible = true;
    receiptItems.balance = 0;
    receiptItems.amountHeld = 0;
    receiptItems.amountCaptured = 0;
    receiptItems.items = [];
    receiptItems.currentItem = { index: -1 };
    receiptItems.currentSelectedTransaction = { index: -1 };
    receiptItems.currentSelectedTab = { index: -1 };
    receiptItems.receiptLines = {};
    modals.transactionsView.isTransactionSelected = false;
    modals.tabsView.isTabSelected = false;
  }

  return (
    <>
      <ConnectionError connectionStatus={connectionStatus} />
      <div id="mainContainer" className="container container__customize">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="business-brand text-center full-screen-switcher">
              <div className="business-brand__client-logo">
                <img src={retailLogo} alt="Dojo" />
              </div>
              <div className="business-brand__client-name">
                Papi's Boutique
              </div>
            </div>
            <ModalTransactionsOrTabs
              displayMode={0}
              modals={modals}
              setModals={setModals}
              getCurrencySymbol={getCurrencySymbol}
              receiptItems={receiptItems}
              setReceiptItems={setReceiptItems}
              clearPreviouslySelectedItems={clearPreviouslySelectedItems}
              clearReceiptItems={clearReceiptItems}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8">
            <div className="f5f5f5-bg pd10">
              <Title
                modals={modals}
                receiptItems={receiptItems}
              />
              <div className="row receipt-and-products-container">
                <PosReceipt
                  modals={modals}
                  receiptItems={receiptItems}
                  getCurrencySymbol={getCurrencySymbol}
                  setReceiptItems={setReceiptItems}
                  clearPreviouslySelectedItems={clearPreviouslySelectedItems}
                />
                <PosItemSelection
                  modals={modals}
                  receiptItems={receiptItems}
                  getCurrencySymbol={getCurrencySymbol}
                  setReceiptItems={setReceiptItems}
                  posItems={posItems}
                />
              </div>
            </div>
          </div>
        </div>
        <PosButtons
          modals={modals}
          setModals={setModals}
          currency={currency}
          receiptItems={receiptItems}
          setReceiptItems={setReceiptItems}
          clearPreviouslySelectedItems={clearPreviouslySelectedItems}
          clearReceiptItems={clearReceiptItems}
          rest={rest}
        />

        <ModalTransactionsOrTabs
          displayMode={1}
          modals={modals}
          getCurrencySymbol={getCurrencySymbol}
          setModals={setModals}
          receiptItems={receiptItems}
          setReceiptItems={setReceiptItems}
          clearPreviouslySelectedItems={clearPreviouslySelectedItems}
          clearReceiptItems={clearReceiptItems}
        />

        {withLog && <NetworkLog />}

      </div>

      <ModalTabsSelection
        modals={modals}
        setModals={setModals}
        getCurrencySymbol={getCurrencySymbol}
        receiptItems={receiptItems}
        setReceiptItems={setReceiptItems}
        rest={rest}
      />

      <ModalPDQSelection
        modals={modals}
        setModals={setModals}
        currency={currency}
        receiptItems={receiptItems}
        setReceiptItems={setReceiptItems}
        clearPreviouslySelectedItems={clearPreviouslySelectedItems}
        clearReceiptItems={clearReceiptItems}
        rest={rest}
      />

      <ModalCapture
        modals={modals}
        setModals={setModals}
        getCurrencySymbol={getCurrencySymbol}
        receiptItems={receiptItems}
        setReceiptItems={setReceiptItems}
        clearPreviouslySelectedItems={clearPreviouslySelectedItems}
        rest={rest}
      />

      <Footer />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <RestProvider>
            <div className="App">
              <HomeComponent />
            </div>
          </RestProvider>
        </QueryParamProvider>
      </Provider>
    </BrowserRouter>
  )
}

export default App;
