export const REST_REQUEST_CREATED = "REST_REQUEST_CREATED";
export const REST_REQUEST_FINISHED = "REST_REQUEST_FINISHED";

export function restRequestCreated(id, method, path, headers, data) {
  return {
    type: REST_REQUEST_CREATED,
    id,
    method,
    path,
    headers,
    data,
  };
}

export function restRequestFinished(id, response) {
  return {
    type: REST_REQUEST_FINISHED,
    id,
    response,
  };
}
