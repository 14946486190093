import React from 'react';

function ModalPDQSelection(props) {

  const { modals, setModals, currency, receiptItems, setReceiptItems, clearPreviouslySelectedItems, clearReceiptItems, rest } = props;

  const transactions = modals.transactionsView.transactions;
  const tabs = modals.tabsView.tabs;

  const pdqList = modals.pdqView.pdqs.map((pdq, index) => {
    return (
      <div key={index} data-id={index} onClick={async(e) => await selectPDQ(e)} className="col-lg-12 modal-pdq-link__container">
        <div className="modal-pdq-link">{pdq.tid}</div>
      </div>
    )
  });

  async function selectPDQ(e) {
    const selectedPdqIndex = parseInt(e.currentTarget.getAttribute('data-id'));
    const terminalId = modals.pdqView.pdqs[selectedPdqIndex].terminalId;
    const balance = receiptItems.balance;
    const items = receiptItems.items;
    const currentTransaction = transactions[receiptItems.currentSelectedTransaction.index];

    unselectTransactionOrTab()

    switch (modals.pdqView.for) {
      default: break;
      case "addToTab":
        resetModal();
        await rest.sendCreateManualPaymentIntentRequest(modals, setModals, terminalId, balance, items, currency);
        break;
      case "cardPayment":
        resetModal();
        rest.sendCreateAutoPaymentIntentRequest(modals, setModals, terminalId, balance, items, currency);
        break;
      case "refundPayment":
        resetModal();
        rest.sendCreateMatchedRefundTerminalSession(modals, setModals, currentTransaction.pid, terminalId, balance, currency);
        break;
    }
    unselectTransactionOrTab()
  }

  function unselectTransactionOrTab() {
    clearReceiptItems();
    clearPreviouslySelectedItems(transactions, -1)
    clearPreviouslySelectedItems(tabs, -1)
    setReceiptItems({...receiptItems});
    setModals({ ...modals })
  }

  function hideModal(e) {
    if (e.target.classList.contains("modal-pdq") || e.target.classList.contains("modal-background")) {
      resetModal();
    }
  }

  function resetModal() {
    modals.pdqView.isVisible = false;
    modals.pdqView.for = null
    setModals({ ...modals })
  }

  return (
    <div onClick={(e) => hideModal(e)} className={`modal-background ${modals.pdqView.isVisible === true ? "show" : ""}`}>
      <div className={`modal modal-pdq custom-modal fade bs-example-modal-sm ${modals.pdqView.isVisible === true ? "show" : ""}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h4 className="modal-title" id="myModalLabel">Select PDQ</h4>
            </div>
            <div className="modal-body">
              <div className="row text-center" id="prds">
                {pdqList.length > 0 && pdqList}
                {pdqList.length === 0 && <div style={{ "padding": "20px 10px" }}>You currently have no available PDQs. Please ensure you add at least one PDQ or ensure any existing PDQs are not busy.</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ModalPDQSelection }