import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { PAYMENT_STATUS_AUTHORIZED } from '../../rest/omniApi';

function PosReceiptItem(props) {
  const { index, name, price, quantity, modals, receiptItems, setReceiptItems, getCurrencySymbol, clearPreviouslySelectedItems } = props;

  const currentItemIsSelected = receiptItems.items[index].selected;
  const isTabSelected = modals.tabsView.isTabSelected;
  const isTransactionSelected = modals.transactionsView.isTransactionSelected;
  const tabs = modals.tabsView.tabs;

  function updateSelectedItem(e) {
    if (receiptItems.isVisible && !CurrentRowIsNotSelected()) {
      const selectedIndex = parseInt(e.currentTarget.getAttribute('data-id'));
      clearPreviouslySelectedItems(receiptItems.items, index);
      receiptItems.items[index].selected = !currentItemIsSelected;
      receiptItems.currentItem = { index: currentItemIsSelected ? -1 : selectedIndex, name: name, price: price, quantity: quantity };
      setReceiptItems({ ...receiptItems });
    }
  }

  function rowContent() {
    return (
      <>
        <div className="receipt__selected-product-name pull-left">{name.toUpperCase()}</div>
        <div className="receipt__selected-product-price pull-right">
          <CurrencyFormat value={parseFloat(price / 100)} prefix={getCurrencySymbol()} displayType={'text'} fixedDecimalScale={true} decimalScale={2} />
        </div>
        <div className="receipt__selected-product-price pull-right margin-left receipt-item-quantity">
          x {quantity}
        </div>
      </>
    );
  }

  function CurrentRowIsNotSelected() {
    return !(receiptItems.currentItem.index === -1 || receiptItems.items[index].selected === true);
  }

  function CurrentStatusIsNotAModifiableStatus() {
    return ![
      PAYMENT_STATUS_AUTHORIZED
    ].includes(tabs[receiptItems.currentSelectedTab.index].status)
  }

  return (
    <>
      {isTabSelected === false && isTransactionSelected === false &&
        <div data-id={index}
          className={`receipt__selected-product__container
            ${CurrentRowIsNotSelected() ? "disabled-button" : "removableProduct"}
            ${currentItemIsSelected ? "selectedRemovableProduct" : ""}`
          }
          onClick={(e) => updateSelectedItem(e)}
        >
          {rowContent()}
        </div>
      }
      {isTabSelected === true && isTransactionSelected === false &&
        <div data-id={index}
          className={`receipt__selected-product__container
            ${CurrentRowIsNotSelected() || CurrentStatusIsNotAModifiableStatus() ? "disabled-button" : "removableProduct"}
            ${currentItemIsSelected ? "selectedRemovableProduct" : ""}`
          }
          disabled={CurrentRowIsNotSelected() || CurrentStatusIsNotAModifiableStatus()}
          onClick={(e) => updateSelectedItem(e)}
        >
          {rowContent()}
        </div>
      }
      {isTabSelected === false && isTransactionSelected === true &&
        <div data-id={index} className={`receipt__selected-product__container disabled-button`}>{rowContent()}</div>
      }
    </>
  );
}

export { PosReceiptItem };